export const type_fixed = "FIXED";
export const type_intangible = "INTANGIBLE";
export const type_consumables = "CONSUMABLES";
export const types = [
    {value: type_fixed, label: "固定资产"},
    {value: type_intangible, label: "无形资产"},
    {value: type_consumables, label: "耗材"}
]
export const typeDesc = (type: string) => {
    return findLabelByValue(types, type)
}

const findLabelByValue = (arr: any[], value: string|null) => {
    let r = arr.filter(i => i.value == value)
    if (r.length > 0) {
        return r[0].label
    }
    return ''
}
export const unitArr=[
    {value: "个", label: "个"},
    {value: "台", label: "台"},
    {value: "套", label: "套"},
    {value: "支", label: "支"},
]

export const depreciationMethods = [
    {value: "INSTANT", label: "当月入账当月折旧"},
    {value: "DELAY", label: "当月入账下月折旧"},
    {value: "NONE", label: "不参于折旧"},
]

export const depreciationMethodDescText=(method:string|null)=>{
    return findLabelByValue(depreciationMethods,method)
}

export const action_rk= "RK";
export const action_ly = "LY";
export const action_tk = "TK";
export const action_bf = "BF";
export const fixed_actions = [
    {value: action_ly, label: "领用"},
    {value: action_tk, label: "退库"},
    {value: action_bf, label: "报废"}
]

export const fixedActionDesc = (action: string) => {
    return findLabelByValue(fixed_actions, action)
}
//资产状态
export const fixed_asset_status_using = "USING";
export const fixed_asset_status_idle = "IDLE";
export const fixed_asset_status_discard = "DISCARD";
export const fixedAssetStatus = [
    {value: fixed_asset_status_using, label: "使用中"},
    {value: fixed_asset_status_idle, label: "闲置"},
    {value: fixed_asset_status_discard, label: "报废"},
]
export const canSelectFixedAssetStatus = [
    {value: fixed_asset_status_using, label: "使用中"},
    {value: fixed_asset_status_idle, label: "闲置"},
]
//变更类型
export const fixed_asset_change_type_ly = "USING";//领用
export const fixed_asset_change_type_tk = "RETURN";//退库
export const fixed_asset_change_type_bf = "DISCARD";//报废
export const fixed_asset_change_type_rk = "REGISTRATION";//入库

export const task_status_init="INIT";
export const task_status_processing="PROCESSING";
export const task_status_completed="COMPLETED";

export const office_asset_currency_list = [
    {value: "CNY", },
    {value: "USD", },
    {value: "EUR", },
    {value: "AUD", },
    {value: "GBP", },
    {value: "HKD", },
    {value: "JPY", },
    {value: "SGD", },
    {value: "CAD", },
    {value: "SEK",},
]
